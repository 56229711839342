<template>
   
    <van-action-sheet v-model="show" title="设置参数" close-icon="close">
         
        <div class="form">
            <div class="form-item block" style="margin-top: 0;padding-top: 10px;">
                <label class="required">商品图</label>
                <div>
                    <van-uploader 
                        v-model="form.fileList" 
                        multiple  
                        :max-count="4"
                        :after-read="afterRead" 
                        :before-read="beforeRead"
                        upload-icon="plus"
                    />
                </div>
            </div>
            <div class="form-item block">
                <label class="required">价格</label>
                <van-field label="¥" label-width="10" v-model="form.price" placeholder="请输入价格" />
            </div>

            <div class="form-item block">
                <label class="required">库存</label>
                <van-field v-model="form.inventory" placeholder="请输入数量" :disabled="disabledInput" />
            </div>
        </div>
        <div  style="padding: 20px 18px 18px 23px;">
            <van-row :gutter="14">
                <van-col :span="12">
                    <van-button color="#F2F2F2" type="info" size="small" block round @click="show = false" >
                        <span style="color: #212121;">取消</span>
                    </van-button>
                </van-col>
                <van-col span="12">
                    <van-button color="#D53A26" type="primary" size="small" block round @click="submit" >确认</van-button>
                </van-col>
            </van-row>
        </div>
    </van-action-sheet>
</template>

<script>
export default {
    name: "SetParams",
    data() {
        return {
            show: false,
            form: {
                fileList: [],
                price: '',
                inventory: ''

            },

            disabledInput: false
        }
    },

    methods: {
        submit() {
            if (this.form.fileList.length === 0) {
                 this.$toast('请选择头像');
                 return
            }

            if (this.form.price === '') {
                 this.$toast('请输入价格');
                 return;
            }
            if (!this.disabledInput && !this.form.inventory ) {
                 this.$toast('请输入库存');
                 return;
            }
            this.$emit('confirm');
            this.show = false
        },
        beforeRead(file) {
            if (file.type !== 'image/jpeg') {
                this.$toast('请上传 jpg 格式图片');
                return false;
            }
            return true;
        },
        // 组件上传
        afterRead(file) {
            file.status = 'uploading';
            file.message = '上传中...';
            console.log(file.content); // 给到的是一个base64字符串

            //上传请求
            setTimeout(() => {
                // file.status = 'failed';
                // file.message = '上传失败';
                file.status = 'success';
                file.message = '上传成功';
                // 上传完了之后可以把后台返回的链接给到content
                // file.content = res.data.url
            }, 1000);
        },
   
        showPop() {
            this.show = true
        }
    },

    mounted() {
        let data = localStorage.getItem('ShelvesDetail')
        if (data) {
            data = JSON.parse(data)

            this.disabledInput = data.syncOfInventory === '1'
        }
    }
}

</script>
<style lang="less" scoped>
::v-deep .van-uploader__preview, ::v-deep .van-uploader__upload {
    height: 64px;
    width: 77px;
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 10px;
    border-radius: 5px;
}

::v-deep .van-uploader__upload {
    border: 1px dashed #DDDDDD;
    background-color: #fff;

}
::v-deep .van-image {
    height: 64px;
    width: 77px;
    border-radius: 5px;
}
::v-deep .van-action-sheet__header {
    padding-top: 10px;
    padding-bottom: 5px;
}
::v-deep .van-action-sheet__close {
    top: 13px;
}

.form {
    .form-item {
        padding: 26px 18px 0;

        label {
            min-width: 105px;
            margin-bottom: 13px;
            font-size: 14px;
            font-weight: 500;
            color: #212121;
            position: relative;
            display: block;
            padding-left: 5px;
            &.required::after {
                content: '*';
                font-size: 14px;
                position: absolute;
                left: -2px;
                top: 0;
                color: #f00;
            }

            i {
                font-style: normal;

                font-size: 12px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #212121;
            }
        }
        .van-cell {
            border: 1px solid #D53A26;
            border-radius: 4px;
            height: 32px;
            padding-top: 3px;
            padding-bottom: 0;
        }

        .van-field--disabled {
            border-color: #eee;
            background-color: #eee;
        }
    }
}
</style>