<template>
    <div class="page-gray">
        <div class="page-title">设置规格</div>

        <div class="title">选择规格项目</div>
        <van-checkbox-group v-model="specifications" @change="onSpecificationsChange">
            <van-checkbox
                shape="square"
                :icon-size="px2rem(15)"
                checked-color="#D53A26"
                v-for="item in specificationsList"
                :name="item.value">
                <span class="checkBox_text">{{item.name}}</span>
            </van-checkbox>
        </van-checkbox-group>

        <template v-for="item in selectSpecificationsList" >
            <div class="title">{{item.name}}</div>
            <div class="sub_specifications_list">
                <div v-for="child in item.children" :class="{item: true, active: isActive(item.value, child.value)}" @click="select(item.value, child.value)">{{child.name}}</div>
            </div>
        </template>
 

        <div  v-if="computedList.length > 0" class="title van-hairline--top" :style="{paddingTop: px2rem(22), marginTop: px2rem(13)}">
            设置
            <div class="handle" v-if="!batch" @click="batch = true; allCheck = false; batchList = []">
                <img src="@/assets/images/shelves/batch.png" alt="" />
                <span>批量</span>
            </div>
            <div class="handle" v-if="batch" @click="batch = false">
                <span>取消批量</span>
            </div>
        </div>
        <!-- 只有选中的规格有两个及以上才显示 -->
        <van-tabs v-model="tabActive" sticky v-if="tabList.length > 0" @change="onTabChange">
            <van-tab title="全部" name="all"></van-tab>
            <van-tab v-for="item in tabList" :title="item.name" :name="String(item.value)"></van-tab>
        </van-tabs>

        <van-checkbox-group v-model="batchList">
            <div class="list">
                <div class="item van-hairline--bottom" v-for="item in renderList" @click="batch ? void 0 : $refs.setParams.showPop()">
                    <van-checkbox v-if="batch" :name="item.value" :icon-size="px2rem(15)" checked-color="#D53A26"  shape="square"/>
                    <div class="imgBox">
                        <van-image
                            :width="px2rem(35)"
                            :height="px2rem(30)"
                            src=""
                        />
                    </div>
                    <div class="content">
                        <div class="name">{{item.name}}</div>
                        <div class="right">
                            <div>¥&nbsp;60.00</div>
                            <div>库存&nbsp;&nbsp;1000</div>
                        </div>
                    </div>
                </div>
            </div>
        </van-checkbox-group>

        <div  style="padding: 20px 18px 18px 18px;" class="buttonGroup" v-if="computedList.length > 0 && !batch">
            <van-row :gutter="14">
                <van-col :span="12">
                    <van-button color="#F2F2F2" type="info" size="small" block round @click="show = false" >
                        <span style="color: #212121;">取消</span>
                    </van-button>
                </van-col>
                <van-col span="12">
                    <van-button color="#D53A26" type="primary" size="small" block round >确认</van-button>
                </van-col>
            </van-row>
        </div>

        <div  style="padding: 20px 18px 18px 18px;" class="batchGroup" v-if="computedList.length > 0 && batch">
           <van-checkbox @click="computedAllCheck" v-model="allCheck" :icon-size="px2rem(15)" checked-color="#D53A26"  shape="square">全选</van-checkbox>

           <div class="right">
                 <van-button color="#D53A26" type="primary" size="small"  round @click="toSet">设置</van-button>
                <van-button color="#D53A26" type="primary" size="small"  round   @click="batch = false">完成</van-button>
           </div>
        </div>
        <set-params ref="setParams" @confirm="onSetParamsConfirm" />
    </div>
</template>

<script>
import setParams from './eidtComponents/setParams.vue'
// 随便写的生成规格数据， 后期可删除
let subChildren = []
for (let i = 170;  i < 190; i++) {
    subChildren.push({
        name: i + 'cm',
        value: Math.floor(Math.random() * 1e5)
    })
}
export default {
  components: { setParams },
    name: "SetMultiform",

    data() {
        return {
            allCheck: false,
            batch: false,
            batchList: [],
            specifications: [],
            specificationsMap: new Map(),
            specificationsList: [{
                name: '标准规格',
                value: 1,
                children: subChildren
            },{
                name: '颜色',
                value: 2,
                children: [{
                    name: '红色',
                    value: 'red'
                }, {
                    name: '黄色',
                    value: 'yellow'
                }, {
                    name: '蓝色',
                    value: 'blue'
                }]
            }],

            tabList: [],
            tabActive: 'all',



            computedList: [], // 计算过后的list
            renderList: [], //实际展示的list， 因为要筛选， 筛选条件为tab的tabActive， 数据来源为computedList
        }
    },

    computed: {
        // 筛选被选中的规格, 因为知识中间区域展示， 所以直接用computed， 省事
        selectSpecificationsList() {
            return this.specificationsList.filter(e => this.specifications.includes(e.value))
        }
    },

    watch: {
        batchList() {
            // 先看看有多少能选的
            let canSelectLen = this.batchList.length
            this.allCheck = (canSelectLen === this.renderList.length) // 当可选数量跟已经选择的数量一样 就设置全选按钮选中
        }
    },

    methods: {
        onSetParamsConfirm() {
            this.batch = false
        },
        toSet() {
            if ( this.batchList.length === 0) {
                this.$toast('请选择商品规格');
                return;
            }

            this.$refs.setParams.showPop()
        },
        computedAllCheck() {
            this.$nextTick(() => {
                // 全选 反选
                if (this.allCheck) {
                    // 先过滤被禁用的
                    this.batchList = this.renderList.map(e => e.value);
                } else {
                    this.batchList = []
                }
            })
        },
        onTabChange() {
            let list = JSON.parse(JSON.stringify(this.computedList))
            this.allCheck = false;
            this.batchList = []
            if (this.tabActive === 'all') {
                this.renderList = list;
            } else {
                this.renderList = list.filter(e => {
                    let values = e.value.split('/');
                    return values.includes(this.tabActive)
                })
            }
        },
        isActive(parent, child) {
            if (!this.specificationsMap.get(parent)) {
                return false
            }
            return this.specificationsMap.get(parent).includes(child)
        },
        select(parent, child) {
            let data = this.specificationsMap.get(parent);

            if (data.includes(child)){
                data.splice(1, data.indexOf(child))
            } else {
                data.push(child)
            }

            this.specificationsMap.set(parent, data)
            this.$forceUpdate()

            this.getRenderList()
        },
        onSpecificationsChange() {
            // 这里还要创建一个map， 来存选中的规格， 只有选中的规格才能放入下面的设置列表
           this.specifications.forEach(e => {
                // 如果map里面存在了 就不去设置它，避免覆盖
                if (this.specificationsMap.get(e)) {
                    return;
                }
                this.specificationsMap.set(e, [])
           })

           //上面的代码已经把选中的放入到map中，现在要把不在选中的规格中的删除掉
            for (let i of this.specificationsMap) {
                if (!this.specifications.includes(i[0])) {
                    this.specificationsMap.delete(i[0])
                }

            }

            this.$forceUpdate()

            this.getRenderList()
        },

        // 计算下面的列表
        getRenderList() {
            let _stack = [];
            for (let i of this.specificationsMap) {
                let ids = this.specificationsMap.get(i[0]); // 存取的是选择的规格值的ID
                let parent = this.selectSpecificationsList.find(e => e.value === i[0]);
                if (!parent) { return; }
                _stack.push(parent.children.filter(e => ids.includes(e.value)))
            }
            this.tabActive = 'all'
            this.renderList = []
            this.computedList = [];
            this.tabList = []
            this.allCheck = false;
            this.batchList = []
            // if (this.specifications.length === 1) {
            //     this.tabActive = 'all'
            
            // }
          
            if (_stack.length > 1) {
                let result = []
                _stack.sort((a, b) => b.length - a.length);
                let lastOne = _stack[_stack.length - 1]
                this.tabList = lastOne
                let fn = () => {
                    _stack[0].forEach(item1 => {
                        return _stack[1].forEach(item2 => {
                            result.push({
                                name: `${item1.name}/${item2.name}`,
                                price: 0,
                                inventory: 0,
                                img: '',
                                value: `${item1.value}/${item2.value}`,
                            })
                        });
                    });
                    _stack = _stack.slice(2)
                    _stack.unshift(result);
                    result = []
                    if(_stack.length > 1) {
                        fn()
                    }
                }
                fn();
            }
            this.computedList = _stack[0];
            this.renderList = JSON.parse(JSON.stringify(_stack[0]))
        }

    }
}

</script>
<style lang="less" scoped>
.page-gray {
    background-color: #fff;
    padding: 1px 13px;
}
.page-title {
    line-height: 15px;
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #212121;
    padding-top: 20px;
    padding-bottom: 5px;
}

.title {
    line-height: 15px;
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #212121;
    margin-top: 22px;
    margin-bottom: 15px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .handle {
        display: flex;
        align-items: center;
        img {
            height: 10px;
            width: 10px;
            margin-right: 3px;
        }
        span {
            line-height: 11px;
            font-size: 11px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #212121;
        }
    }
}

.van-checkbox {
    margin-bottom: 10px;
    margin-right: 24px;
    display: inline-flex;
}
.checkBox_text {
    font-size: 14px;
    font-weight: 400;
    color: #212121;
    line-height: 1;
}

.sub_specifications_list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -22px;
    .item {
        margin-bottom: 10px;
        line-height: 30px;
        font-size: 12px;
        font-weight: 400;
        color: #212121;

        min-width: 62px;
        width: 62px;
        height: 30px;
        background: #F7F8F9;
        border-radius: 5px;
        text-align: center;
        margin-right: 11px;
        &.active {
            background: #F9EDEB;
            color: #D61619;
            line-height: 28px;
            border: 1px solid #D61619;
        }

    }
}
.list {
    padding-bottom: 100px;
    .item {
        display: flex;
        align-items: center;
        padding: 10px 0 12px;
        .van-checkbox {
            margin-bottom: 0;
            margin-right: 7px;
        }
        .imgBox {
            width: 36px;
            min-width: 36px;
            height: 30px;
            background: #DCDCDC;
            border-radius: 2px;
            margin-right: 10px;
        }

        .content {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .name {
                font-size: 12px;
                font-weight: 400;
                color: #212121;
                margin-bottom: 3px;
            }

            .right {
                text-align: center;
                font-size: 10px;
                font-weight: 400;
                color: #D61619;
                line-height: 15px;
            }
        }
    }
}

.buttonGroup {
    position: fixed;
    bottom: 0;
    width: 100vw;
    left: 0;
    background-color: #fff;
}

.batchGroup {
    height: 66px;
    display: flex;
    align-items: center;
     left: 0;
    justify-content: space-between;

    position: fixed;
    bottom: 0;
    width: 100vw;
    background-color: #fff;

    .van-checkbox {
        margin-bottom: 0;
        margin-right: 7px;
    }
    .right {
        .van-button {
            width: 91px;
            margin-left: 11px;
        }
    }
}
::v-deep .van-tab { flex: inherit; margin-right:30px ;}
::v-deep .van-image__error-icon { font-size: 20px; color: #999;}
::v-deep .van-image__error {
    background-color: #DCDCDC;
}
</style>
